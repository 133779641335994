import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import ChangeEventModal from "../ChangeEventModal/ChangeEventModal";
import UsersSettings from "../UsersSettings/UsersSettings";
import * as XLSX from "xlsx";

export default function AllUsersTable({ row }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("Name");
  const [selected, setSelected] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [selectedRole, setSelectedRole] = React.useState("");
  const [selectedFranchise, setSelectedFranchise] = React.useState("");

  const exportToExcel = (data) => {
    // Create a new workbook and a new worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "All Users");

    // Export the workbook to a file
    XLSX.writeFile(wb, "all_users_data.xlsx");
  };

  // Inside your AllUsersTable component:
  const handleExport = () => {
    // Call the export function with the filtered rows
    const excelFilter = filteredRows.map((row) => {
      let userRole = "";
      if (row.Role.admin) {
        userRole = "admin";
      } else if (row.Role.manager) {
        userRole = "manager";
      } else if (row.Role.user) {
        userRole = "user";
      }
      return {
        Name: row.Name,
        Email: row.Email,
        FranchiseType: row.franchiseType,
        Role: userRole,
      };
    });

    exportToExcel(excelFilter);
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };
  const handleFranchiseChange = (event) => {
    setSelectedFranchise(event.target.value);
  };

  React.useEffect(() => {
    setRows(row);
  }, [row]);

  function descendingComparator(a, b, orderBy) {
    if (typeof a[orderBy] === "string" && typeof b[orderBy] === "string") {
      return b[orderBy]?.toLowerCase().localeCompare(a[orderBy]?.toLowerCase());
    } else {
      return b[orderBy] < a[orderBy] ? -1 : 1;
    }
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const headCells = [
    {
      id: "Name",
      numeric: true,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "Email",
      numeric: true,
      disablePadding: false,
      label: "Email",
    },
    {
      id: "Franchise",
      numeric: true,
      disablePadding: false,
      label: "Franchise",
    },

    {
      id: "Status",
      numeric: true,
      disablePadding: false,
      label: "Status",
    },
    {
      id: "Settings",
      numeric: true,
      disablePadding: false,
      label: "Action",
    },
  ];

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              style={{ fontSize: "18px" }}
              key={headCell.id}
              align={headCell.numeric ? "center" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    onRequestSort: PropTypes.func.isRequired,
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function EnhancedTableToolbar(props) {
    const { numSelected } = props;
    return (
      <Toolbar
        className=" d-flex justify-content-end "
        // sx={{
        //   pl: { sm: 2 },
        //   pr: { xs: 1, sm: 1 },
        // }}
      >
        {numSelected > 0 && (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="blue"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        )}
        {numSelected > 0 && (
          <Tooltip title="AddNew">
            <IconButton>
              <ChangeEventModal
                newSelected={selected}
                setSelected={setSelected}
              />
            </IconButton>
          </Tooltip>
        )}

        {/* <ExportToExcelButton
            filename="exported_data"
            sheetname="Sheet 1"
            data={rows}
          /> */}

        {/* <ImportExcel /> */}
        {/* <SearchText list={rows}/> */}
      </Toolbar>
    );
  }
  const findTrueKey = (obj) => {
    for (const [key, value] of Object.entries(obj)) {
      if (value === true) {
        return key;
      }
    }
    return null; // or handle the case where no true value is found
  };
  const isSelected = (id) => selected.indexOf(id) !== -1;

  const filteredRows = rows.filter((row) => {
    // Convert the search query to lowercase for case-insensitive comparison
    const query = searchQuery.toLowerCase();

    // Function to recursively search through all values in the object
    const searchObject = (obj) => {
      for (let key in obj) {
        if (obj[key] !== null && obj[key] !== undefined) {
          if (typeof obj[key] === "string" || typeof obj[key] === "number") {
            if (obj[key].toString().toLowerCase().includes(query)) {
              return true;
            }
          } else if (typeof obj[key] === "object") {
            if (searchObject(obj[key])) {
              return true;
            }
          }
        }
      }
      return false;
    };

    // Perform the search on the entire row object
    const matchesQuery = searchObject(row);

    // Handle filtering by role if selectedRole is set

    // Handle filtering by role if selectedRole is set
    const role = findTrueKey(row.Role)?.toLowerCase() || "";

    // Handle filtering by franchise if selectedFranchise is set
    const franchise = row.franchiseType?.toLowerCase() || "";

    return (
      matchesQuery &&
      (selectedRole === "" || role === selectedRole) &&
      (selectedFranchise === "" ||
        franchise === selectedFranchise.toLowerCase())
    );
  });

  const visibleRows = React.useMemo(
    () =>
      stableSort(filteredRows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, filteredRows]
  );

  // const handleClick = (event, id) => {
  //   const selectedIndex = selected.indexOf(id);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, id);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelected(newSelected);
  // };
  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <div className="flex justify-between items-center ">
          <div className="p-3 align-items-center d-flex flex-wrap items-center gap-2">
            <div>
              <label
                className="block text-black text-sm font-bold mb-2 w-fit"
                htmlFor="select2"
              >
                Search
              </label>
              <TextField
                variant="outlined"
                className="border rounded-3"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                fullWidth
              />
            </div>
            <div className="w-60">
              <label
                className="block text-black text-sm font-bold mb-2 w-fit"
                htmlFor="select2"
              >
                Filter Role
              </label>
              <TextField
                select
                value={selectedRole}
                onChange={handleRoleChange}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
                className="border-bottom w-100"
                // sx={{
                //   borderRadius: 2, // Adjusts the rounded corners
                //   '.MuiOutlinedInput-root': {
                //     borderWidth: 2, // Adds a thicker border
                //   },
                //   '.MuiInputLabel-root': {
                //     fontSize: '1rem', // Adjusts the label font size
                //   },
                //   '.MuiOutlinedInput-input': {
                //     padding: '10px 14px', // Adjusts input padding for better alignment
                //   },
                //   '.MuiOutlinedInput-notchedOutline': {
                //     borderColor: '#ccc', // Changes the border color to a light grey
                //   },
                // }}
              >
                <option value="">All</option>
                <option value="admin">Admin</option>
                <option value="user">User</option>
                <option value="manager">Manager</option>
              </TextField>
            </div>
            <div className="w-60">
              <label
                className="block text-black text-sm font-bold mb-2 w-fit"
                htmlFor="select2"
              >
                Filter Franchise
              </label>
              <TextField
                select
                value={selectedFranchise}
                onChange={handleFranchiseChange}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
                className="border-bottom w-100"
              >
                <option value="">All</option>
                <option value="Immunology & Neuroscience">Immunology & Neuroscience</option>
                <option value="gtx">Gtx</option>
                <option value="oncology">Oncology</option>
                <option value="cardiovascular">Cardiovascular</option>
                <option value="medical">Medical</option>
                <option value="Value, Access & KAM">Value, Access & KAM</option>
                <option value="BE&E">BE&E</option>
              </TextField>
            </div>
          </div>
          <div className="px-3">
            <button
              onClick={handleExport}
              className="btn btn-success text-white"
            >
              <i className="fa-solid fa-file-arrow-down fs-5 text-white mr-2"></i>
              <span>EXPORT</span>
            </button>
          </div>
        </div>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <TableContainer>
          <Table
            // ref={tableRef}
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            {rows.length !== 0 && (
              <TableBody>
                {visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.ID);
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.ID}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                      className=" border-b   odd:bg-white  even:bg-gray-100 "
                    >
                      <TableCell align="center">
                        <span className="text-blueColor font-normal text-lg ">
                          {row.Name}
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        <span className="text-blueColor font-normal text-lg ">
                          {row.Email}
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        <span className="text-blueColor font-normal text-lg ">
                          {row.franchiseType}
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        {row?.Condition?.Blocked ? (
                          <b className="text-danger font-normal text-lg">
                            Blocked
                          </b>
                        ) : (
                          <b className="text-success font-normal text-lg">
                            Active
                          </b>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <UsersSettings row={row} />
                      </TableCell>
                    </TableRow>
                  );
                })}
                {/* Handle empty rows */}
                {rowsPerPage - filteredRows.length > 0 && (
                  <TableRow
                    style={{
                      height:
                        (dense ? 33 : 53) * (rowsPerPage - filteredRows.length),
                    }}
                  >
                    <TableCell colSpan={headCells.length} />
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <div className="my-3">
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Paper>
    </Box>
  );
}
