import { useContext, useEffect, useState, useCallback } from "react";
import BasicTable from "../../Components/BasicTable/BasicTable";
import { FireBaseContext } from "../../Context/FireBase";

export const Dashboard = () => {
  const [WeeksInfo, setWeeksInfo] = useState([
    {
      icon: "fa-solid fa-calendar-day",
      times: 0,
      calen: "Today",
      color: "#EC9A1E",
    },
    {
      icon: "fa-solid fa-calendar-week",
      times: 0,
      calen: "Week",
      color: "#E74A21",
    },
    {
      icon: "fa-solid fa-calendar",
      times: 0,
      calen: "Month",
      color: "#0460A9",
    },
  ]);
  const { getData, currentUserRole, eventsQueryAccordingToUserRole } =
    useContext(FireBaseContext);
  const [data, setData] = useState([]);
  const [eventsAccordingToRole, setEventsAccordingToRole] = useState([]);


    // Memoize the function to fetch data according to the user's role
    const fetchData = useCallback(() => {
      if (currentUserRole) {
        getData(eventsQueryAccordingToUserRole(), setData);
      }
    }, [currentUserRole, getData, eventsQueryAccordingToUserRole]);
  
    useEffect(() => {
      fetchData(); // This will now only change if currentUserRole or related values change
    }, [fetchData]);

    const updateEventsAccordingToRole = useCallback(() => {
      if (data.length !== 0) {
        setEventsAccordingToRole(data);
      }
    }, [data]);
  
    useEffect(() => {
      updateEventsAccordingToRole();
    }, [updateEventsAccordingToRole]);



  return (
    <div className="d-flex flex-column gap-4 align-items-start ">
      <div className="container-fluid container-md">
        <h2 className="my-3 ">
          {" Hi, "}
          {localStorage.getItem("User")
            ? JSON.parse(localStorage.getItem("User"))
                .Name?.split(" ")
                ?.map(
                  (part) =>
                    part[0]?.toUpperCase() + part?.substring(1).toLowerCase()
                )
                .join(" ")
            : ""}
        </h2>
        <div className="flex  justifybetween items-center w-100">
          <div className="flex flex-column gap-2 items-start DashboardTableParen w-100 ">
            <div className="md:flex items-center justify-center gap-4 w-full ">
              {WeeksInfo.map((item, ind) => (
                <div
                  style={{background: item.color}}
                  key={ind}
                  className="flex flex-col items-center justify-center py-6 px-2 shadow-md rounded-lg w-full md:w-1/3  text-white "
                >
                  <div className="flex items-center justify-center mb-2">
                    <i className={`${item.icon} text-4xl `}></i>
                  </div>
                  <div className="text-center flex items-center justify-center gap-2">
                    <h5 className="text-xl font-bold" >{item.times}</h5>
                    <h5 className="">{item.calen}</h5>
                    
                  </div>
                </div>
              ))}
            </div>

            <div className="w-100">
              <BasicTable
                row={eventsAccordingToRole}
                WeeksInfo={WeeksInfo}
                setWeeksInfo={setWeeksInfo}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
