import { useContext, useEffect, useState } from "react";
import { FireBaseContext } from "../../../Context/FireBase";
export const FranchisedropDown = ({ SetError, formErrors }) => {
  const { newEvent, setNewEvent, currentUserRole } =
    useContext(FireBaseContext);
  const [filteredFranchise, setFilteredFranchise] = useState([]);

  const handleChange = (event) => {
    setNewEvent({ ...newEvent, Franchise: event.target.value });
    if (event.target.value) {
      SetError({ ...formErrors, Franchise: "" });
    } else {
      SetError({ ...formErrors, Franchise: "Required" });
    }
  };

  const data = [
    { types: "Immunology & Neuroscience", label: "Immunology & Neuroscience" },
    { types: "GTx", label: "GTx" },
    { types: "Oncology", label: "Oncology" },
    { types: "Cardiovascular", label: "Cardiovascular" },
    { types: "Medical", label: "Medical" },
    { types: "Value, Access & KAM", label: "Value, Access & KAM" },
    { types: "BE&E", label: "BE&E" },
  ];

  useEffect(() => {
    if (currentUserRole) {
      if (currentUserRole.manager || currentUserRole.user) {
        const filtered = data.filter(
          (franchise) =>
            franchise.types === currentUserRole.franchiseType.trim()
        );

        setFilteredFranchise(filtered);
      } else {
        setFilteredFranchise(data);
      }
    }
  }, [currentUserRole]);

  return (
    <>
      <label
        className="block text-black text-sm font-bold mb-2"
        htmlFor="select2"
      >
        Franchise
      </label>

      <select
        // labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        name="Franchise"
        value={newEvent.Franchise}
        onChange={handleChange}
        label="Franchise"
        className="appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
      >
        <option value="selected" hidden>
          Select
        </option>
        {filteredFranchise.map((item, index) => (
          <option value={item.types} key={index}>
            {item.label}
          </option>
        ))}
      </select>
    </>
  );
};