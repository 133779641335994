import { useContext, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { doc, updateDoc } from "firebase/firestore";

import { FireBaseContext } from "../../Context/FireBase";

// Styled component for hidden file input
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  whiteSpace: "nowrap",
  width: 1,
});

export default function UploadBtn({ id, info, element }) {
  const [selectedFile, setSelectedFile] = useState(null);

  const { SubscribersRefrence } = useContext(FireBaseContext);

  const handleSelect = (e) => {
    // setSelectedFile(e.target.files[0]);
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedFile(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const subscriberDocRef =
    element.refId && typeof element.refId === "string" ? doc(SubscribersRefrence, element.refId) : null;

  useEffect(() => {
    if (
      !element.refId ||
      typeof element.refId !== "string" ||
      !selectedFile ||
      !subscriberDocRef
    ) {
      return;
    }

    let isMounted = true; // Track if the component is mounted

    const handleUpload = async () => {
      const signatureParams = {
        sign64data: selectedFile,
        eventID: element?.eventID,
        nationalId: element?.nationalId,
      };

      try {
        const response = await fetch(
          "https://event.orevan.org/api/upload-signature",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: new URLSearchParams(signatureParams),
          }
        );

        const result = await response.json();
        if (result.message == 'Signature uploaded') {
          await updateDoc(subscriberDocRef, {
            sign64data: selectedFile,
            signURL: result.url
          });
        }

      } catch (error) {
        console.error("Error:", error);
      }
    };

    handleUpload();

    return () => {
      isMounted = false; // Clean up on component unmount
    };
  }, [selectedFile]);

  // Optionally, you can display an error message or disabled button if `id` is invalid
  if (!id || typeof id !== "string") {
    return <p>Error: Invalid ID</p>;
  }

  return (
    <div>
      <Button
        component="label"
        variant="contained"
        className={info}
        sx={{
          backgroundColor: "white",
          padding: "10px", // Adjust padding to control the background size
          "& .MuiSvgIcon-root": {
            fontSize: "1rem", // Adjust font size to control the icon size
            color: "blue", // Optional: change icon color
          },
        }}
        startIcon={<CloudUploadIcon />}
      >
        <VisuallyHiddenInput type="file" onChange={handleSelect} />
      </Button>
    </div>
  );
}
