import { useContext, useEffect, useState } from "react";
import "./ChosenCardStyle.css";
import { doc, onSnapshot } from "firebase/firestore";
import { FireBaseContext } from "../../Context/FireBase";
import { useParams } from "react-router-dom";
import { HelperContext } from "../../Context/HelperContext";

export const ChosenCard = () => {
  const { dbID } = useParams();
  const { EventRefrence, UserRef } = useContext(FireBaseContext);

  const { FormateCost, statusCase } = useContext(HelperContext);
  const [ResultData, setResultData] = useState(null);
  const [createdBy, setCreatedBy] = useState({});

  useEffect(() => {
    const itemRef = doc(EventRefrence, dbID);
    const unsubscribeEvent = onSnapshot(itemRef, (eventSnapshot) => {
      if (eventSnapshot.exists()) {
        const eventData = eventSnapshot.data();
        setResultData(eventData);

        // Fetch the user data in real-time using onSnapshot
        const userRef = doc(UserRef, eventData.CreatedByID);
        const unsubscribeUser = onSnapshot(userRef, (userSnapshot) => {
          if (userSnapshot.exists()) {
            const userData = userSnapshot.data();
            setCreatedBy(userData);
          } else {
            console.log("No such user document!");
          }
        });

        return () => unsubscribeUser();
      }
    });

    return () => unsubscribeEvent();
  }, [dbID]);

  return (
    // Dashboard Events Counts Card ----------------------------------
    <div className="w-100">
      {ResultData ? (
        <div className=" choosenCard bg-white p-4 rounded-2 rounded w-100 shadow">
          <div className="w-full px-2 ">
            <div className="flex justify-between items-center">
              <h4 className="wrappingItems text-white p-2  w-fit">
                ID : {ResultData.Id}
              </h4>
              <h4
                className={`${
                  statusCase(ResultData.eventDate, ResultData.endDate).color
                } text-white p-2 rounded w-10 text-center text-xl font-semibold`}
              >
                {statusCase(ResultData.eventDate, ResultData.endDate).status}
              </h4>
            </div>

            <div className="flex gap-x-6 ">
              <div className="w-full md:w-1/2  mb-2">
                <div className="">
                  <p className="my-2 text-gray-600 font-thin">
                    <b>Event Name:</b>
                  </p>
                  <p className="my-2 text-black font-semibold text-2xl">
                    {ResultData.eventName}
                  </p>
                </div>
                <div className="flex flex-wrap">
                  <div className="w-full md:w-1/2">
                    <div className="">
                      <p className="my-2 text-gray-600 font-thin">
                        <b>BeSure:</b>
                      </p>
                      <p className="my-2 text-black text-xl">
                        {ResultData.BeSure}
                      </p>
                    </div>
                  </div>
                  <div className="w-full md:w-1/2">
                    <div className="">
                      <p className="my-2 text-gray-600 font-thin">
                        <b>Franchise:</b>
                      </p>
                      <p className="my-2 text-black text-xl ">
                        {ResultData.Franchise}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap">
                  <div className="w-full md:w-1/2">
                    <div className="">
                      <p className="my-2 text-gray-600 font-thin">
                        <b>Start Date:</b>
                      </p>
                      <p className="my-2 text-black text-xl">
                        {" "}
                        {ResultData.eventDate}
                      </p>
                    </div>
                  </div>
                  <div className="w-full md:w-1/2">
                    <div className="">
                      <p className="my-2 text-gray-600 font-thin">
                        <b>End Date:</b>
                      </p>
                      <p className="my-2 text-black text-xl">
                        {" "}
                        {ResultData.endDate}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap">
                  <div className="w-full md:w-1/2">
                    <div className="">
                      <p className="my-2 text-gray-600 font-thin">
                        <b>Start Time:</b>
                      </p>
                      <p className="my-2 text-black text-xl">
                        {" "}
                        {ResultData.DateFromHours}
                      </p>
                    </div>
                  </div>
                  <div className="w-full md:w-1/2">
                    <div className="">
                      <p className="my-2 text-gray-600 font-thin">
                        <b>End Time:</b>
                      </p>
                      <p className="my-2 text-black text-xl">
                        {" "}
                        {ResultData.DateEndHours}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap">
                  <div className="w-full md:w-1/2">
                    <div className="">
                      <p className="my-2  text-gray-600 font-thin">
                        <b>City:</b>
                      </p>
                      <div className="flex flex-wrap gap-2">
                        {ResultData.city.map((item, index) => (
                          <div
                            className="bg-orangeColor rounded text-white p-2 w-fit text-sm"
                            key={index}
                          >
                            {item}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="w-full md:w-1/2">
                    <div className="">
                      <p className="my-2  text-gray-600 font-thin">
                        <b>Created By:</b>
                      </p>
                      <p className="my-2 text-black text-xl">
                        {" "}
                        {createdBy ? createdBy.Name : " "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full  md:w-1/2">
                <div className="">
                  <p className="my-2 text-gray-600 font-thin">
                    <b>Cost per Delegate:</b>
                  </p>
                  <p className="my-2 text-black text-xl">
                    {FormateCost(
                      ResultData.CostperDelegate,
                      ResultData.EventCurrency
                    )}
                  </p>
                </div>
                <div className="">
                  <p className="my-2 text-gray-600 font-thin">
                    <b>Event Cost:</b>
                  </p>
                  <p className="my-2 text-black text-xl">
                    {FormateCost(
                      ResultData.eventCost,
                      ResultData.EventCurrency
                    )}
                  </p>
                </div>
                <div className="">
                  <p className="my-2 text-gray-600 font-thin">
                    <b>Transfer of value:</b>
                  </p>

                  <div className="flex flex-wrap gap-2">
                    {ResultData.TransferOfValue.map((item, index) => (
                      <div
                        className="bg-gray-400 rounded flex items-center  w-fit  my-2"
                        key={index}
                      >
                        {/* <p className="my-2 "> */}
                        <span className="text-white text-sm p-2">
                          {item.types}{" "}
                        </span>
                        <span className="text-white bg-orangeColor rounded-tr rounded-br flex items-center justify-center h-100 p-2 text-sm">
                         {FormateCost(item.value, ResultData.EventCurrency )}
                        </span>
                        {/* </p> */}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex flex-wrap gap-4">
                  <div className="">
                    <p className="my-2 text-gray-600 font-thin">
                      <b>Background Color:</b>
                    </p>
                    <div
                      className="border"
                      style={{
                        backgroundColor: `${ResultData.bgColor}`,
                        width: "50px",
                        height: "20px",
                        borderRadius: "5px",
                      }}
                    ></div>
                  </div>
                  <div className="">
                    <p className="my-2 text-gray-600 font-thin">
                      <b>Font Color:</b>
                    </p>
                    <div
                      className="border"
                      style={{
                        backgroundColor: `${ResultData.fontColor}`,
                        width: "50px",
                        height: "20px",
                        borderRadius: "5px",
                      }}
                    ></div>
                  </div>
                  <div className="">
                    <p className="my-2 text-gray-600 font-thin">
                      <b>Button Color:</b>
                    </p>
                    <div
                      className="border"
                      style={{
                        backgroundColor: `${ResultData.btnColor}`,
                        width: "50px",
                        height: "20px",
                        borderRadius: "5px",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-100 d-flex justify-content-center">
          <div className="dot-spinner">
            <div className="dot-spinner__dot"></div>
            <div className="dot-spinner__dot"></div>
            <div className="dot-spinner__dot"></div>
            <div className="dot-spinner__dot"></div>
            <div className="dot-spinner__dot"></div>
            <div className="dot-spinner__dot"></div>
            <div className="dot-spinner__dot"></div>
            <div className="dot-spinner__dot"></div>
          </div>
        </div>
      )}
    </div>
  );
};
