import { PreviewCard } from "../../PreveiwCard/PreviewCard";
export const StepFour = () => {
  return (
    <>
    <div className="d-flex flex-col justify-content-center">
      <PreviewCard />
      <span className="mt-4 text-center text-danger"><b>Remember to</b> Update your app to the latest version for the best experience.</span>
    </div>
    </>
  );
};
