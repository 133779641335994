import { useContext, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { FireBaseContext } from "../../Context/FireBase";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import {
  deleteDoc,
  doc,
  setDoc,
  getDocs,
  getDoc,
  serverTimestamp,
  query,
  where,
  updateDoc,
} from "firebase/firestore";
import UpdateSubModel from "../UpdateSubModel/UpdateSubModel";
export default function SettingsBtn({ refCollection, rowId, row }) {
  const { dbID } = useParams();
  const ITEM_HEIGHT = 38;
  const [anchorEl, setAnchorEl] = useState(null);
  const { SubscribersDeletedRef, EventRefrence } = useContext(FireBaseContext);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const removeSubscriber = async (id) => {
    try {
      // Query the collection to find the document based on the user id
      const q = query(
        refCollection,
        where("id", "==", id),
        where("email", "==", row.email),
        where("nationalId", "==", row.nationalId)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const firstDoc = querySnapshot.docs[0];
        const docRef = firstDoc.ref; // Get the reference to the document
        const item = firstDoc.data(); // Get the document data

        // Show the confirmation prompt to the user
        swal({
          title: "Are you sure You want to delete this subscriber?",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then(async (willDelete) => {
          if (willDelete) {
            // Proceed with deletion
            swal({ icon: "success" });

            // Delete the document
            await deleteDoc(docRef);

            // Log the deletion in the SubscribersDeletedRef collection
            await setDoc(doc(SubscribersDeletedRef, id), {
              event: dbID,
              ID: id,
              timing: serverTimestamp(),
              ...item, // Spread the data from the deleted document
            });
            const subscribers = querySnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            const totalCost = subscribers.reduce(
              (total, subscriber) => total + subscriber.CostperDelegate,
              0
            );

            const eventRef = doc(EventRefrence, dbID);
            const dataEvent = await getDoc(eventRef);
            const total = dataEvent.data().eventCost - totalCost;

            await updateDoc(eventRef, { eventCost: total });

            handleClose(); // Close any open UI elements
          }
        });
      } else {
        console.error("No such document to delete!");
        swal({
          title: "Error",
          text: "No subscriber found to delete.",
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error deleting document:", error);
      swal({
        title: "Error",
        text: "There was an issue deleting the subscriber.",
        icon: "error",
      });
    }
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "15ch",
          },
        }}
      >
        <MenuItem>
          <div className="d-flex   justify-content-between align-items-center w-100  gap-2 ">
            <UpdateSubModel user={row} />
          </div>
        </MenuItem>
        <MenuItem onClick={() => removeSubscriber(rowId)}>
          <div className="d-flex   justify-content-center align-items-center w-75  gap-2 ">
            <i className={`fa-solid fa-trash darkBlue w-25`}></i>
            <span className="   w-75 darkBlue">Delete</span>
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
}
