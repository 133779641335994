import { createContext } from "react";
import data from "../Json/currencies.json";

export const HelperContext = createContext();

const HelperContextProvider = ({ children }) => {
  function FormateCost(number, eventCurrency) {
    const currencyObj = data.data.find((item) => item.name === eventCurrency);

    const cost = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 20, // Adjust based on the precision you want
    }).format(number);

    return cost + " " + currencyObj.symbol;
  }
  const parseDate = (dateString) => {
    let date = `${dateString}`;
    const [day, month, year] = date.split("/");
    return new Date(`${year}-${month}-${day}`);
  };

  const statusCase = (start, end) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    // Parse the start and end dates
    start = parseDate(start);
    end = parseDate(end);
    start.setHours(0, 0, 0, 0);
    end.setHours(23, 59, 59, 999);

    // Compare dates and return status accordingly
    if (currentDate < start) {
      return { status: "Pending", color: "bg-warning" };
    } else if (
      (currentDate >= start && currentDate <= end) ||
      currentDate == start
    ) {
      return { status: "Started", color: "bg-info" };
    } else {
      return { status: "Ended", color: "bg-danger" };
    }
  };

  return (
    <HelperContext.Provider value={{ FormateCost, statusCase }}>
      {children}
    </HelperContext.Provider>
  );
};
export default HelperContextProvider;
