import "./StepThreeStyle.css";
// import mobileImg from "../../../assets/mobile-frame-without-background-free-png.png";
import frame from "../../../assets/frame.png";
import logo from "../../../assets/Novartis logo.png";
import orevanLogo from "../../../assets/orevanEventsLogo.png";
import { FireBaseContext } from "../../../Context/FireBase";
import { useContext, useEffect } from "react";
import ColorPickerInput from "../../ColorPickerInput/ColorPickerInput";
export const StepThree = () => {
  const { newEvent, setNewEvent } = useContext(FireBaseContext);
  const randomXToY = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };
  useEffect(() => {
    setNewEvent({ ...newEvent, Id: randomXToY(100000, 999999) });
  }, []);
  return (
    <div className=" mt-5">
      <div className=" grid grid-cols-3 gap-4">
        <div>
          <label className="block text-black text-sm font-bold mb-2">
            Background Color
          </label>
          <ColorPickerInput type={"bgColor"} />
        </div>
        <div>
          <label className="block text-black text-sm font-bold mb-2">
            Font Color
          </label>
          <ColorPickerInput type={"fontColor"} />
        </div>
        <div>
          <label className="block text-black text-sm font-bold mb-2">
            Button Color
          </label>
          <ColorPickerInput type={"btnColor"} />
        </div>
      </div>

      <div className="StepThreeBg d-flex justify-content-center">
        <div className="frame"
          style={{backgroundImage: `url(${frame})` }}
        >
          {/* <img
            src={mobileImg}
            alt="mobileLogo"
            style={{ backgroundColor: newEvent.bgColor }}
          /> */}
          <div className="StepThreeContent d-flex flex-col align-items-center justify-content-center gap-2" style={{ backgroundColor: newEvent.bgColor }}>
            <div className="w-100">
              <img
                className="themeLogo"
                src={logo}
                alt="themeLogo"
                style={{ objtectFit: "contain" }}
              />
            </div>
            <div className="bg-white rounded rounded-3 d-flex flex-col align-items-center justify-content-center gap-2 w-4/6 px-4 py-4">
              <p className="m-0 text-center ">
                <b className="text-center" style={{ color: newEvent.fontColor }}>
                  Welcome to <br /> {newEvent.eventName}
                </b>
              </p>
              <button
                className="w-50 rounded rounded-2 border-0  py-1 text-white"
                style={{ backgroundColor: newEvent.btnColor }}
                disabled
              >
                Register
              </button>
            </div>
            <div className="w-100">
              <img
                className="themeLogo orevanLogo"
                src={orevanLogo}
                alt="themeLogo"
                style={{ objtectFit: "contain" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


