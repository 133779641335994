import { useContext, useCallback, useState, useEffect } from "react";
import { FireBaseContext } from "../../Context/FireBase";
import {
  getDocs,
  where,
  query,
  getDoc,
  doc,
  onSnapshot,
} from "firebase/firestore";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { useParams } from "react-router-dom";

const ExportSfda = ({
  data,
  filename,
  sheetname,
  onExportStart,
  onExportEnd,
}) => {
  const { SubscribersRefrence, TransferOfValuesRef } =
    useContext(FireBaseContext);


  const [TOVs, setTOVs] = useState([]);

  useEffect(() => {
    const getTovData = async () => {
      try {
        onSnapshot(TransferOfValuesRef, (snapshot) => {
          const newData = snapshot.docs.map((doc) => doc.id);
          setTOVs(newData);

         
        })
         
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    getTovData();
  }, []);

  const handleExport = useCallback(async () => {
    if (TOVs.length > 0) {
      onExportStart();
      
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet(sheetname);
      const finalDataToExport = [];

      await Promise.all(
        data?.map(async (item) => {
          const subscribersQuery = query(
            SubscribersRefrence,
            where("eventID", "==", item.ID.toString())
          );

          await getDocs(subscribersQuery).then((snapshot) => {
            finalDataToExport.push(
              {
                "Event ID": item.Id,
                "Event Name": item.eventName,
                "Franchise Name": item.Franchise?.toString(),
                "Event City": item.city.map((city) => city).join(","),
                "Cost per Delegate": item.CostperDelegate,
                "Event Cost": item.eventCost?.toString(),
                PO: item.PO,
                BeSure: item.BeSure,
                "Event Date": item.eventDate,
              },
              ...snapshot.docs.map((ele) => {
                const subItem = ele.data();
                const totalGrant = subItem.TransferOfValue.reduce(
                  (first, second) => {
                    return Number(first) + Number(second.value);
                  },
                  0
                );

                let returnedTovs = [];

                TOVs.forEach((tov) => {
                  const total = subItem.TransferOfValue.filter(
                    (transfer) => transfer.types === tov
                  ).reduce(
                    (total, transfer) => total + Number(transfer.value),
                    0
                  );

                  returnedTovs.push({
                    type: tov,
                    value: total,
                  });
                });

                let values = {
                  "Title/اللقب": "Dr",
                  "FirstName/الاسم الاول": subItem.name,
                  "LastName/الاسم الاخير": subItem.LastName,
                  Specialitzation: subItem.specialty,
                  "Other Specialitzation (optional)": "",
                  "Professional Classification Number": subItem.licenceId,
                  "National/Resident ID": subItem.nationalId,
                  "Mobile Number / رقم الجوال": subItem.tel,
                  "Email/الايميل": subItem.email,
                  "Form Of Payment ": "cash or cash equivalent",
                  "Grant purpose": subItem.TransferOfValue.map(
                    (item) => `${item.types} = ${item.value}`
                  ).join(","),
                  "Payment Amount": totalGrant,
                  "Date of Payment": item.eventDate,
                  Signature: subItem.signURL
                    ? { text: "Signature Link", hyperlink: subItem.signURL }
                    : "",
                  City: subItem.city,
                };

                returnedTovs.forEach((tov) => {
                  values[tov.type] = tov.value;
                });

                return values;
              })
            );
          });
        })
      );

      // Add headers
      const headersList = [
        "Event Name",
        "Franchise Name",
        "Event City",
        "Cost per Delegate",
        "Event Cost",
        "PO",
        "BeSure",
        "Event Date",
        "Event ID",
        "Title/اللقب",
        "FirstName/الاسم الاول",
        "LastName/الاسم الاخير",
        "Specialitzation",
        "Other Specialitzation (optional)",
        "Professional Classification Number",
        "National/Resident ID",
        "Mobile Number / رقم الجوال",
        "Email/الايميل",
        "Form Of Payment ",
        "Grant purpose",
        ...TOVs,
        "Payment Amount",
        "Date of Payment",
        "Signature",
        "City",
      ];
   
      worksheet.addRow([...headersList]);

      // Set the background color for the entire row
      const rowIndex = 1;
      const row = worksheet.getRow(rowIndex);
      row.eachCell({ includeEmpty: true }, (cell, index) => {
        const color =
          index > 9 ? "80FFFF00" : index === 9 ? "80009900" : "ff00b0f0";
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: color },
        };
        cell.font = { size: 12 };
        cell.alignment = { horizontal: "center", vertical: "middle" };
      });

      // Add data rows
      let eventRow = {};
      finalDataToExport.forEach((rowItem, index) => {
        if (rowItem.hasOwnProperty("Event Name")) {
          eventRow = { ...rowItem };
        } else {
          rowItem = {
            ...eventRow,
            ...rowItem,
          };
        }
        const holder = headersList.map((head) => {
          const convertItem = isNaN(Number(rowItem[head]))
            ? rowItem[head]
            : Number(rowItem[head]);
          return rowItem[head] ? convertItem : "";
        });

        worksheet.addRow([...holder]);

        const currentRow = worksheet.getRow(index + 2);
        currentRow.eachCell({ includeEmpty: true }, (cell) => {
          if (
            rowItem.hasOwnProperty("Event Name") &&
            !rowItem.hasOwnProperty("Title/اللقب")
          ) {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFADD8E6" }, // light blue
            };
          }
          cell.alignment = { horizontal: "center", vertical: "middle" };
          if (typeof cell.value === "number") {
            cell.numFmt = "0";
          }
        });
      });

      // Set column widths
      worksheet.columns.forEach((column, colIndex) => {
        let maxLength = 0;
        worksheet.eachRow({ includeEmpty: true }, (row) => {
          const cellValue = row.getCell(colIndex + 1).text;
          maxLength = Math.max(maxLength, cellValue ? cellValue.length : 0);
        });
        column.width = maxLength + 5;
      });

      worksheet.getRow(1).height = 20;

      // Save the workbook
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      saveAs(blob, filename);
 

      onExportEnd();
    }
   
  }, [
    TOVs,
    data,
    filename,
    sheetname,
    SubscribersRefrence,
    onExportStart,
    onExportEnd,
  ]);

  return (
    <i className="fa-solid fa-download" onClick={handleExport}>
      <span className="fs-6 fw-light"> SFDA</span>
    </i>
  );
};

export default ExportSfda;
