import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import DataTable from "../../Components/DataTable/DataTable";
import { FireBaseContext } from "../../Context/FireBase";
import SearchFormik from "../../Components/SearchFormik/SearchFormik";
import {
  doc,
  getDoc,
} from "firebase/firestore";

export const Events = () => { 
  const {
    events,
    getData,
    setEvents,
    currentUserRole,
    eventsQueryAccordingToUserRole,
    UserRef,
  } = useContext(FireBaseContext);

  const [informations, setInformations] = useState([]);
  const [rows, setRows] = useState([]);
  const [startDateFilter, setStartDateFilter] = useState(""); // Moved to parent
  const [endDateFilter, setEndDateFilter] = useState("");

  const prevEventsRef = useRef(events);

  // Memoize the event fetching logic
  const fetchDataForItems = useCallback(async () => {
    const promises = informations.map(async (item) => {
      const userSnapshot = await getDoc(doc(UserRef, item.CreatedByID));
      if (userSnapshot.exists()) {
        const userData = userSnapshot.data();
        item.CreatedBy = userData; // Store the whole user object
      } else {
        item.CreatedBy = "Unknown";
      }

      return item;
    });

    const results = await Promise.all(promises);

    const eventCostsChanged = prevEventsRef.current.some((event, idx) => {
      // Check if the event and results[idx] are defined
      const currentResult = results[idx];
      if (!event || !currentResult) {
        return false; // If either is undefined, skip the comparison
      }

      return event.eventCost !== currentResult.eventCost;
    });

    if (eventCostsChanged) {
      setEvents(results.reverse()); // Update context only if event costs are different
      prevEventsRef.current = results.reverse(); // Update reference to reflect new state
    }

    // Only update context if the new data is different
    if (
      prevEventsRef.current.length !== results.length ||
      !prevEventsRef.current.every(
        (event, idx) =>
          event.Id === results[idx].Id && event.Status === results[idx].Status
      )
    ) {
      setEvents(results.reverse());
      prevEventsRef.current = results.reverse();
    }
  }, [informations, UserRef, setEvents]);

  // Fetch event data based on user role
  useEffect(() => {
    if (currentUserRole) {
      getData(eventsQueryAccordingToUserRole(), setInformations);
    }
  }, [currentUserRole, getData, eventsQueryAccordingToUserRole]);

  // Process fetched event information
  useEffect(() => {
    if (informations.length > 0) {
      fetchDataForItems();
    }
  }, [informations, fetchDataForItems]);

  return (
    <div className="d-flex flex-column container-fluid container-md gap-3 EventsPageParent">
      <h2>Events</h2>
      {!informations.length ? (
        <div
          className="w-100 d-flex justify-content-center align-items-center"
          style={{ height: "calc(100vh - 150px)" }}
        >
          <p>Create Your First Event</p>
        </div>
      ) : (
        <>
          <SearchFormik
            rows={events}
            setRows={setRows}
            setStartDateFilter={setStartDateFilter}
            setEndDateFilter={setEndDateFilter}
            startDateFilter={startDateFilter}
            endDateFilter={endDateFilter}
          />
          <DataTable
            row={rows}
            startDateFilter={startDateFilter}
            endDateFilter={endDateFilter}
          />
        </>
      )}
    </div>
  );
};
